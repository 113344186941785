import * as React from 'react';
import db from '../firebase';
import { useEffect, useState } from "react";
import { collection, getDocs, query, addDoc, Timestamp, deleteDoc, doc  } from "firebase/firestore";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import NavBar from '../components/navbar';

const eventUpdates = collection(db, 'updates');
// // const ninetyDaysAgo = Timestamp.fromDate(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));

const columns = [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'description', headerName: 'Description', width: 600 },
    { field: 'date', headerName: 'Date', width: 130 },
];

export default function Updates() {
    const [event, setEvents] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [uploading, setUploading] = useState(false);
    const [eventDate, setEventDate] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(eventUpdates);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {

                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort((a, b) => b.date - a.date);
                setEvents(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])

    const handleSubmit = async (e) => {
        e.preventDefault();

        setUploading(true);


        // Convert eventDate to Firestore Timestamp
        const timestamp = Timestamp.fromDate(new Date(eventDate));

        // Add event data to Firestore
        const eventDoc = {
            name: eventName,
            date: timestamp,
            description: eventDescription
        };

        try {
            await addDoc(collection(db, 'updates'), eventDoc);
            Swal.fire({
                title: 'Success!',
                text: 'Updates added successfully!',
                icon: 'success',
                confirmButtonText: 'Cool'
              });
              handleClose();
              window.location.reload();
            // console.log('Updates added successfully!');
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error adding updates.',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
              handleClose();
            // console.error('Error adding updates: ', error);
        } finally {
            setUploading(false);
        }
    };

    const handleModal = () => {
        setShowAddModal(true);
    }

    const handleClose = () => {
        setShowAddModal(false);
    }

    const handleDelete = async (id) => {
        try {
          await deleteDoc(doc(db, 'updates', id));
          setEvents(event.filter(record => record.id !== id));
          Swal.fire({
            title: 'Success!',
            text: 'Deleted Successfully',
            icon: 'success',
            confirmButtonText: 'Cool'
          });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error deleting document',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
        }
      };

    return (
        <>
        <NavBar />
            <div className='updates_table'>
                <h1>Upcoming Event Updates</h1>
                <button onClick={handleModal}>Add</button>
            </div>
            <div className='updates_data'>
                <Table responsive>
                    <thead>
                        <tr>
                            {columns.map((col, id) => <th key={id}>{col.headerName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {event.map((row, id) =>
                            <tr key={id}>
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                <td>{row.date && row.date.toDate().toDateString()}</td>
                                <td>
                                    <button onClick={() => handleDelete(row.id)} className='custom_button'>
                                    Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <Modal show={showAddModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Upcoming Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the event name"
                                onChange={(e) => setEventName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" rows={3}
                                onChange={(e) => setEventDescription(e.target.value)}
                                placeholder='Describe the event' />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Enter the date"
                                onChange={(e) => setEventDate(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button type="submit" disabled={uploading} onClick={handleSubmit}>
                        {uploading ? 'Uploading...' : 'Submit Event'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
