import db from '../firebase';
import { useEffect, useState } from "react";
import { collection, getDocs, query, doc, getDoc, updateDoc, deleteDoc, where, deleteField } from "firebase/firestore";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Swal from 'sweetalert2';
import React from 'react';
import NavBar from '../components/navbar';

const eventUpdates = collection(db, 'sponsors');
// const ninetyDaysAgo = Timestamp.fromDate(new Date(Date.now() - 90  24  60  60  1000));

const columns = [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'class', headerName: 'Class', width: 600 },
    { field: 'section', headerName: 'Section', width: 130 },
    { field: 'parent', headerName: 'Parent Name', width: 130 },
    { field: 'single_parent', headerName: 'Single Parent', width: 130 },
    { field: 'phone', headerName: 'Phone Number', width: 130 },
];

export default function Sponsor() {
    const [event, setEvents] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [parentName, setParentName] = useState('');
    const [studentName, setStudentName] = useState('');
    const [studentClass, setStudentClass] = useState('');
    const [studentSection, setStudentSection] = useState('');
    const [contactNumber, setContactNumber] = useState(null);
    const [singleParent, setSingleParent] = useState(Boolean);
    const [status, setStatus] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            try {
                const queue = query(eventUpdates);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                console.log("ddd", items);
                displayData(items);

            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])

    function displayData(data){
                const groupedById = data.reduce((accumulator, item) => {
                    for (const [id, doc] of Object.entries(item)) {
                        if (!accumulator[doc.class]) {
                            accumulator[doc.class] = [];
                          }
                          accumulator[doc.class].push(doc);
                      } 
                    return accumulator;
                  }, {});
                  const entries = Object.entries(groupedById);
                  const firstThree = entries.slice(0, 4);
                  const rest = entries.slice(4);
                  const rearrangedEntries = [...rest, ...firstThree];
                  const rearrangedGroupedById = Object.fromEntries(rearrangedEntries);
                  setEvents(rearrangedGroupedById);
            }

            const handleSubmit = async (e) => {
                e.preventDefault();
        
                if (!studentClass.trim()) {
                    setStatus('Class cannot be empty.');
                    return;
                }
        
                setUploading(true);
        
                const eventDoc = {
                    name: studentName,
                    parent_name: parentName,
                    class: studentClass,
                    section: studentSection,
                    number: contactNumber,
                    single_parent: singleParent
                };
        
                try {
                    const classDocRef = doc(db, 'sponsors', studentClass);

      // Fetch the existing document data
      const docSnapshot = await getDoc(classDocRef);

   
      if (docSnapshot.exists()) {
        const existingData = docSnapshot.data();
        const existingFields = Object.keys(existingData);
        const existingNumbers = existingFields
        .map(field => parseInt(field, 10))
        .filter(num => !isNaN(num));
        const nextFieldNumber = existingNumbers.length > 0 
        ? Math.max(...existingNumbers) + 1 
        : 1;
        const updatedData = {
            ...existingData,
            [nextFieldNumber]: eventDoc,
          };
          await updateDoc(classDocRef, updatedData);
          Swal.fire({
            title: 'Success!',
            text: 'Student added successfully!',
            icon: 'success',
            confirmButtonText: 'Cool'
        }).then(() => {
            setStatus('Student added successfully!');
            handleClose();
            window.location.reload();
        });
      } else {
        setStatus('Document does not exist.');
      }
    } catch (error) {
        Swal.fire({
            title: 'Error!',
            text: 'Error adding student.',
            icon: 'error',
            confirmButtonText: 'Try Again'
          });
          handleClose();
      console.error('Error updating/adding document: ', error);
      setStatus('Error updating/adding document.');
    } finally {
      setUploading(false);
    }
            };

    const handleModal = () => {
        setShowAddModal(true);
    }


    const handleClose = () => setShowAddModal(false);

    const handleDelete = async (data) => {
        try {
            const classDocRef = doc(db, 'sponsors', data.class);
            const docSnapshot = await getDoc(classDocRef);
            if(docSnapshot.exists()){
                const existingData = docSnapshot.data();
                const updates = {};
                const filterArr = Object.entries(existingData);
                filterArr.map(([key,value]) => {
                    if(value.name == data.name) {
                        updates[key] = deleteField();
                    }
                });
                await updateDoc(classDocRef, updates);
                setEvents(prevEvents => {
                    const updatedEvents = { ...prevEvents };
                    Object.keys(updates).forEach(key => {
                        delete updatedEvents[key]; 
                    });
                    return updatedEvents;
                });
                Swal.fire({
                    title: 'Success!',
                    text: 'Deleted successfully!',
                    icon: 'success',
                    confirmButtonText: 'Cool'
                }).then(() => {
                    window.location.reload(); 
                });
            }
        } catch(error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error Deleting.',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
        }
      };

    return (
        <>
      <NavBar />

            <div className='updates_table'>
                <h1>Students List</h1>
                <button onClick={handleModal}>Add</button>
            </div>
            <div className='updates_data'>
                <Table responsive>
                    <thead>
                        <tr>
                            {columns.map((col, id) => <th key={id}>{col.headerName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    {Object.entries(event).map(([group, items]) => (
          <React.Fragment key={group}>
            {Array.isArray(items) && items.length > 0 ? (
              items.map(item => (
                item.name ? (
                  <tr key={item.name}>
                    <td>{item.name}</td>
                    <td>{item.class} </td>
                    <td>{item.section}</td>
                    <td>{item.parent_name}</td>
                    <td>
                      <Form.Check
                        type="switch"
                        id={`switch-${item.id}`}
                        checked={item.single_parent}
                        disabled
                      />
                    </td>
                    <td>{item.number}</td>
                    <td>
                        <button onClick={() => handleDelete(item)} className='custom_button'>
                        Delete
                        </button>
                    </td>
                  </tr>
                ) : null
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">No items available</td>
              </tr>
            )}
          </React.Fragment>
        ))}
                    </tbody>
                </Table>
            </div>
            <Modal show={showAddModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Student</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the student name"
                                onChange={(e) => setStudentName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Class</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the class"
                                onChange={(e) => setStudentClass(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Section</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the section"
                                onChange={(e) => setStudentSection(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Parent Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the parent name"
                                onChange={(e) => setParentName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Single Parent</Form.Label>
                            <Form.Check
                                type="switch"
                                id="custom-switch"
                                onChange={(e) => setSingleParent(e.target.value)}
                                label=""
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Enter the phone number"
                                onChange={(e) => setContactNumber(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button type="submit" disabled={uploading} onClick={handleSubmit}>
                        {uploading ? 'Uploading...' : 'Submit Event'}
                    </button>
                    <p>{status}</p>
                </Modal.Footer>
            </Modal>
        </>
    )
}