import * as React from 'react';
import db from '../firebase';
import { useEffect, useState } from "react";
import { collection, getDocs, query, Timestamp, addDoc, deleteDoc, doc  } from "firebase/firestore";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Swal from 'sweetalert2';
import NavBar from '../components/navbar';

const eventUpdates = collection(db, 'archived_events');
// // const ninetyDaysAgo = Timestamp.fromDate(new Date(Date.now() - 90 * 24 * 60 * 60 * 1000));

const columns = [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'description', headerName: 'Description', width: 600},
    { field: 'date', headerName: 'Date', width: 130 },
    { field: 'images', headerName: 'Images', width: 130 },
    

];

export default function ArchivedEvents() {
    const [event, setEvents] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [eventName, setEventName] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [files, setFiles] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadPreset] = useState('ml_default');

    useEffect(() => {
    
        const fetchData = async () => {
            try {
                const queue = query(eventUpdates);
                const querySnapshot = await getDocs(queue);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort((a, b) => b.date - a.date);
                setEvents(items);
        
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        };
        fetchData();
    }, [])

    const handleFileChange = (event) => {
        setFiles(event.target.files);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (files.length === 0) {
            console.error('No files selected.');
            return;
        }

        setUploading(true);

        const imageUrls = [];

        // Upload each image to Cloudinary
        const uploadImage = async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset);

            try {
                const response = await axios.post(
                    `https://api.cloudinary.com/v1_1/dzigcd2je/image/upload`,
                    formData
                );
                return response.data.secure_url;
            } catch (error) {
                Swal.fire({
                    title: 'Error!',
                    text: 'Error uploading image.',
                    icon: 'error',
                    confirmButtonText: 'Try Again'
                  });
                  handleClose();
                console.error('Error uploading image: ', error);
            }
        };

        // Upload all images and collect their URLs 
        for (const file of files) {
            const imageUrl = await uploadImage(file);
            if (imageUrl) {
                imageUrls.push(imageUrl);
            }
        }

        // Convert eventDate to Firestore Timestamp
        const timestamp = Timestamp.fromDate(new Date(eventDate));

        // Add event data to Firestore
        const eventDoc = {
            name: eventName,
            description: eventDescription,
            date: timestamp,
            imageUrls: imageUrls // Array of image URLs
        };

        try {
            await addDoc(collection(db, 'archived_events'), eventDoc);
            Swal.fire({
                title: 'Success!',
                text: 'Event added successfully!',
                icon: 'success',
                confirmButtonText: 'Cool'
              });
              handleClose();
              window.location.reload();
            console.log('Event added successfully!');
        } catch (error) {
            console.error('Error adding event: ', error);
        } finally {
            setUploading(false);
        }
    };

    const handleModal = () => {
        setShowAddModal(true);
    }

    const handleClose = () => {
        setShowAddModal(false);
    }

    const handleDelete = async (id) => {
        try {
          await deleteDoc(doc(db, 'archived_events', id));
          setEvents(event.filter(record => record.id !== id));
          Swal.fire({
            title: 'Success!',
            text: 'Event Deleted successfully!',
            icon: 'success',
            confirmButtonText: 'Cool'
          });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error deleting event.',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
          console.error('Error deleting document:', error);
        }
      };


    return (
        <>
        <NavBar />
        <div className='events_container'>
            <div className='updates_table'>
                <h1>Events</h1>
                <button onClick={handleModal}>Add</button>
            </div>
            <div className='updates_data'>
                <Table responsive>
                    <thead>
                        <tr>
                            {columns.map((col, id) => <th key={id}>{col.headerName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {event.map((row, id) =>
                            <tr key={id}>
                                <td>{row.name}</td>
                                <td>{row.description}</td>
                                <td>{row.date && row.date.toDate().toDateString()}</td>
                                {/* <td>{row.}</td> */}
                                <td>{row.imageUrls.length}</td>
                                <td>
                                    <button onClick={() => handleDelete(row.id)} className='custom_button'>
                                    Delete
                                    </button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

             <Modal show={showAddModal} onHide={handleClose}>
                 <Modal.Header closeButton>
                     <Modal.Title>Add New Past-Event</Modal.Title>
                 </Modal.Header>
                 <Modal.Body>
                     <Form>
                         <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                             <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the event name"
                                onChange={(e) => setEventName(e.target.value)}
                                autoFocus
                                required
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlTextarea1"
                        >
                            <Form.Label>Description</Form.Label>
                            <Form.Control 
                                as="textarea"
                                placeholder='Describe the event'
                                onChange={(e) => setEventDescription(e.target.value)}
                                required
                                />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Event Date</Form.Label>
                            <Form.Control
                                type="date"
                                placeholder="Enter the date"
                                onChange={(e) => setEventDate(e.target.value)}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Upload Pictures</Form.Label>
                            <Form.Control
                                type="file"
                                placeholder="Upload the file"
                                multiple
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <button type="submit" disabled={uploading} onClick={handleSubmit}>
                        {uploading ? 'Uploading...' : 'Submit Event'}
                    </button>
                </Modal.Footer>
            </Modal>

        </div>
        </>
    )
}