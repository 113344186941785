import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import './App.css';

import Updates from './pages/updates';
import ArchivedEvents from "./pages/archivedEvents";
import Sponsor from "./pages/sponsors";
import Login from "./pages/login";
import Academics from "./pages/academics";

function App() {
  return (
    <div className="App">

      <Router>
        <Routes>
          <Route path="/" element={<Login />} exact/>
          <Route path="/updates" element={<Updates />} />
          <Route path="/archivedEvents" element={<ArchivedEvents />} />
          <Route path="/sponsors" element={<Sponsor />} />
          <Route path="/academics" element={<Academics />} />
          {/* <Route path="/home" element={<HomePage />} /> */}
          {/* <Route path="/about" element={<AboutPage />} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
