import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/footer_logo.png';

export default function NavBar() {
    return(
        <>
      <Navbar bg="dark" data-bs-theme="dark">
        <Container>
          <Navbar.Brand href="/"><img src={Logo} alt="logo" className='logo'/></Navbar.Brand>
          <Nav className="me-auto">
            <Nav.Link href="/updates">Upcoming Events</Nav.Link>
            <Nav.Link href="/archivedEvents">Archived Events</Nav.Link>
            <Nav.Link href="/sponsors">Sponsors</Nav.Link>
            <Nav.Link href="/academics">Academics</Nav.Link>
          </Nav>
          <Navbar.Text>
            <a href="/">Sign Out</a>
          </Navbar.Text>
        </Container>
      </Navbar>
      </>
    )
}