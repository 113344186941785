import * as React from 'react';
import db from '../firebase';
import { useEffect, useState } from "react";
import { collection, getDocs, addDoc, query, deleteDoc, doc, } from "firebase/firestore";
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Swal from 'sweetalert2';
import Card from 'react-bootstrap/Card';
import NavBar from '../components/navbar';


const studentProdigies = collection(db, 'student_prodigies');

const columns = [
    { field: 'student_name', headerName: 'Student Name', width: 130 },
    { field: 'student_class', headerName: 'Class', width: 130 },
    { field: 'total_score', headerName: 'Marks Secured', width: 130 },
    { field: 'student_rank', headerName: 'Rank', width: 130 },
    { field: 'imageUrl', headerName: 'Image', width: 130 },
    { field: 'subjectHighScores', headerName: 'Subject High Scores', width: 200 }
];

export default function Academics() {

    const [studentData, setStudentData] = useState([]);
    const [showAddModal, setShowAddModal] = useState(false);
    const [studentName, setStudentName] = useState('');
    const [studentClass, setStudentClass] = useState('');
    const [studentRank, setStudentRank] = useState('');
    const [studentTotalScore, setStudentTotalScore] = useState('');
    const [file, setFile] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadPreset] = useState('ml_default');
    const [subjects, setSubjects] = useState([{ subject: '', score: '' }]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const q = query(studentProdigies);
                const querySnapshot = await getDocs(q);
                const items = [];
                querySnapshot.forEach((doc) => {
                    items.push({ ...doc.data(), id: doc.id });
                });
                items.sort((a, b) => b.date - a.date);
                setStudentData(items);
            } catch (error) {
                console.error("error fetching data:", error);
            }
        };
        fetchData();
    }, [])

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
    };

    const handleSubjectChange = (index, event) => {
        const values = [...subjects];
        values[index][event.target.name] = event.target.value;
        setSubjects(values);
    };

    const addSubjectField = () => {
        setSubjects([...subjects, { subject: '', score: '' }]);
    };

    const removeSubjectField = (index) => {
        const values = [...subjects];
        values.splice(index, 1);
        setSubjects(values);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!file) {
            console.error('No files selected.');
            return;
        }

        setUploading(true);

        let imageUrl = '';

        // Upload the image to Cloudinary
        const uploadImage = async (file) => {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('upload_preset', uploadPreset);

            try {
                const response = await axios.post(
                    `https://api.cloudinary.com/v1_1/dzigcd2je/image/upload`,
                    formData
                );
                if (response) {
                    console.log("Success");
                }
                return response.data.secure_url;
            } catch (error) {
                console.error('Error uploading image: ', error);
                return null;
            }
        };

        // Upload the single image and store its URL
        imageUrl = await uploadImage(file);

        // Add student data to Firestore
        const studentDoc = {
            student_name: studentName,
            student_class: studentClass,
            total_score: studentTotalScore,
            student_rank: studentRank,
            imageUrl: imageUrl,
            subjectHighScores: subjects // Array of subject and score objects
        };

        try {
            await addDoc(studentProdigies, studentDoc);
            Swal.fire({
                title: 'Success!',
                text: 'Student added successfully!',
                icon: 'success',
                confirmButtonText: 'Cool'
              }).then(() => {
                handleClose();
                window.location.reload();
            });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error adding student.',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
              handleClose();
            console.error('Error adding student: ', error);
        } finally {
            setUploading(false);
        }
    };

    const handleModal = () => {
        setShowAddModal(true);
    }

    const handleClose = () => {
        setShowAddModal(false);
    }
    const handleDelete = async (id) => {
        try {
          await deleteDoc(doc(db, 'student_prodigies', id));
          setStudentData(studentData.filter(record => record.id !== id));
          Swal.fire({
            title: 'Success!',
            text: 'Student deleted successfully!',
            icon: 'success',
            confirmButtonText: 'Cool'
          });
        } catch (error) {
            Swal.fire({
                title: 'Error!',
                text: 'Error deleting student.',
                icon: 'error',
                confirmButtonText: 'Try Again'
              });
          console.error('Error deleting document:', error);
        }
      };
    return (
        <>
            <NavBar />
        <div className='events_container'>
            <div className='updates_table'>
                <h1>Academics</h1>
                <button onClick={handleModal}>Add</button>
            </div>
            <div className='updates_data'>
                <Table responsive>
                    <thead>
                        <tr>
                            {columns.map((col, id) => <th key={id}>{col.headerName}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {studentData.length > 0 ? studentData.map((row, id) =>
                            <tr key={id}>
                                <td>{row.student_name}</td>
                                <td>{row.student_class}</td>
                                <td>{row.total_score}</td>
                                <td>{row.student_rank}</td>
                                <td>{row.imageUrl ? 'Present' : 'Not Present'}</td>
                                <td>
                                    {Array.isArray(row.subjectHighScores) ? row.subjectHighScores.map((score, index) => (
                                        <div key={index}>
                                             {score.subject && score.score ? `${score.subject}: ${score.score}` : "No Data"}
                                        </div>
                                    )) : 'No Data'}
                                </td>
                                <td>
                                    <button onClick={() => handleDelete(row.id)} className='custom_button'>
                                    Delete
                                    </button>
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={columns.length}>No data available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <Modal show={showAddModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add New Student Data</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="studentName">
                            <Form.Label>Student Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Student name"
                                onChange={(e) => setStudentName(e.target.value)}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="studentClass">
                            <Form.Label>Student Class</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Student class"
                                onChange={(e) => setStudentClass(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="totalScore">
                            <Form.Label>Total Score</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Student Total Score"
                                onChange={(e) => setStudentTotalScore(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="studentRank">
                            <Form.Label>Rank</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter the Student Rank"
                                onChange={(e) => setStudentRank(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="fileUpload">
                            <Form.Label>Upload Student Image</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={handleFileChange}
                                required
                            />
                        </Form.Group>
                        <div className="subject-cards">
                            {subjects.map((subject, index) => (
                                <Card key={index} className="mb-3">
                                    <Card.Body>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Subject</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="subject"
                                                value={subject.subject}
                                                placeholder="Enter subject"
                                                onChange={(e) => handleSubjectChange(index, e)}
                                            />
                                        </Form.Group>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Score</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="score"
                                                value={subject.score}
                                                placeholder="Enter score"
                                                onChange={(e) => handleSubjectChange(index, e)}
                                            />
                                        </Form.Group>
                                        <Button variant="danger" onClick={() => removeSubjectField(index)}>Remove</Button>
                                    </Card.Body>
                                </Card>
                            ))}
                            <Button variant="secondary" onClick={addSubjectField} className="mb-3">Add Subject</Button>
                        </div>
                        <Button
                            variant="primary"
                            onClick={handleSubmit}
                            disabled={uploading}
                        >
                            {uploading ? 'Uploading...' : 'Submit'}
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
        </>

    );
}
